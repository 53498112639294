import React from 'react';

const PrivacyPolicy = () => {
  const styles = {
    container: {
      maxWidth: '800px',
      margin: 'auto',
      marginTop:"100px",
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
      lineHeight: '1.6',
    },
    heading: {
      color: '#ffffff',
      fontSize: '24px',
      marginBottom: '10px',
    },
    subheading: {
      color: '#ffffff',
      fontSize: '20px',
      marginBottom: '8px',
    },
    paragraph: {
      color: '#ffffff',
      marginBottom: '15px',
    },
    listItem: {
      color: '#ffffff',
      marginBottom: '8px',
      listStyleType: 'none'
    },
    link: {
      color: '#ffffff',
      textDecoration: 'underline',
    },
  };

  return (
    <div style={styles.container}>
      
      <h1 style={styles.heading}>Privacy Policy</h1>
      <p style={styles.paragraph}><strong>Last updated:</strong> June 15, 2024</p>
      <p style={styles.paragraph}>
        Welcome to memehaha.lol, your go-to platform for meme generation. We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines the types of information we collect, how we use it, how we protect it, and your rights regarding your personal data.
      </p>

      <h2 style={styles.subheading}>1. Information We Collect</h2>
      <h3 style={styles.subheading}>1.1 Personal Information</h3>
      <p style={styles.paragraph}>We may collect the following personal information when you use our services:</p>
      <ul>
        <li style={styles.listItem}><strong>Name</strong></li>
        <li style={styles.listItem}><strong>Email address</strong></li>
        <li style={styles.listItem}><strong>Username</strong></li>
        <li style={styles.listItem}><strong>Profile picture</strong></li>
      </ul>

      <h3 style={styles.subheading}>1.2 Non-Personal Information</h3>
      <p style={styles.paragraph}>We also collect non-personal information which does not directly identify you. This includes:</p>
      <ul>
        <li style={styles.listItem}><strong>IP address</strong></li>
        <li style={styles.listItem}><strong>Browser type and version</strong></li>
        <li style={styles.listItem}><strong>Device information</strong></li>
        <li style={styles.listItem}><strong>Operating system</strong></li>
        <li style={styles.listItem}><strong>Pages visited on our website</strong></li>
        <li style={styles.listItem}><strong>Time and date of visits</strong></li>
        <li style={styles.listItem}><strong>Referring website</strong></li>
      </ul>

      <h3 style={styles.subheading}>1.3 Meme Data</h3>
      <p style={styles.paragraph}>
        When you upload images or text to memehaha.lol for meme generation, we may collect and store the content to provide our services. The memes are processed by AI models provided by <a href="https://www.replicate.com" style={styles.link}>www.openai.com</a> to deliver the generated meme.
      </p>

      <h2 style={styles.subheading}>2. How We Use Your Information</h2>
      <h3 style={styles.subheading}>2.1 Providing and Improving Our Services</h3>
      <ul>
        <li style={styles.listItem}>To process your meme generation requests.</li>
        <li style={styles.listItem}>To personalize and improve your experience on our website.</li>
        <li style={styles.listItem}>To provide customer support.</li>
        <li style={styles.listItem}>To manage user accounts and provide related information.</li>
      </ul>

      <h3 style={styles.subheading}>2.2 Communication</h3>
      <ul>
        <li style={styles.listItem}>To send you updates, newsletters, and promotional materials.</li>
        <li style={styles.listItem}>To respond to your inquiries and requests.</li>
      </ul>

      <h3 style={styles.subheading}>2.3 Analytics and Research</h3>
      <ul>
        <li style={styles.listItem}>To conduct data analysis to improve our services.</li>
        <li style={styles.listItem}>To monitor and analyze usage and trends to better understand how our services are used.</li>
      </ul>

      <h2 style={styles.subheading}>3. Sharing Your Information</h2>
      <h3 style={styles.subheading}>3.1 Service Providers</h3>
      <p style={styles.paragraph}>We may share your information with third-party service providers to help us operate and improve our services, such as:</p>
      <ul>
        <li style={styles.listItem}>Email service providers</li>
        <li style={styles.listItem}>Analytics providers</li>
        <li style={styles.listItem}>Cloud storage providers</li>
      </ul>

      <h3 style={styles.subheading}>3.2 Legal Requirements</h3>
      <p style={styles.paragraph}>We may disclose your information if required by law or in response to valid requests by public authorities.</p>

      <h3 style={styles.subheading}>3.3 Business Transfers</h3>
      <p style={styles.paragraph}>In the event of a merger, acquisition, or sale of assets, your information may be transferred as part of the transaction.</p>

      <h2 style={styles.subheading}>4. Security</h2>
      <p style={styles.paragraph}>
        We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, disclosure, alteration, or destruction. However, no security system is impenetrable, and we cannot guarantee the absolute security of your data.
      </p>

      <h2 style={styles.subheading}>5. Your Rights</h2>
      <h3 style={styles.subheading}>5.1 Access and Correction</h3>
      <p style={styles.paragraph}>You have the right to access and correct your personal information held by us. You can update your information through your account settings or by contacting us directly.</p>

      <h3 style={styles.subheading}>5.2 Deletion</h3>
      <p style={styles.paragraph}>You can request the deletion of your personal data by contacting us. We will process your request in accordance with applicable laws.</p>

      <h3 style={styles.subheading}>5.3 Opt-Out</h3>
      <p style={styles.paragraph}>You can opt out of receiving promotional communications from us by following the unsubscribe instructions in those communications or by contacting us.</p>

      <h2 style={styles.subheading}>6. Cookies and Tracking Technologies</h2>
      <p style={styles.paragraph}>
        We use cookies and similar tracking technologies to collect and use personal information about you. For more information about the types of cookies we use and how to manage your cookie preferences, please visit our <a href="#" style={styles.link}>Cookie Policy</a>.
      </p>

      <h2 style={styles.subheading}>7. Third-Party Links</h2>
      <p style={styles.paragraph}>
        Our website may contain links to third-party websites. We are not responsible for the privacy practices or the content of these websites. We encourage you to read the privacy policies of any linked sites you visit.
      </p>

      <h2 style={styles.subheading}>8. Children's Privacy</h2>
      <p style={styles.paragraph}>
      memehaha.lol is not intended for use by anyone under the age of 18. We do not knowingly collect personal information from anyone under 18. If we become aware that we have collected personal information from a child under 13, we will take steps to delete such information.
      </p>

      <h2 style={styles.subheading}>9. Changes to This Privacy Policy</h2>
      <p style={styles.paragraph}>
        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website and updating the effective date. You are advised to review this Privacy Policy periodically for any changes.
      </p>

      <h2 style={styles.subheading}>10. Contact Us</h2>
      <p style={styles.paragraph}>
        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
      </p>
      <ul>
        <li style={styles.listItem}><strong>Email:</strong> <a href="mailto:admin@memehaha.lol" style={styles.link}>admin@memehaha.lol</a></li>
      </ul>

      <p style={styles.paragraph}>
        By using memehaha.lol, you agree to the terms of this Privacy Policy. Thank you for trusting memehaha.lol with your meme generation needs.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
