// Import the functions you need from the SDKs you need
import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged, signInWithRedirect } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc, getDoc, collection, addDoc, serverTimestamp } from "firebase/firestore"; 
import { BrowserRouter as Router, Route, useNavigate } from 'react-router-dom';
import Fingerprint2 from 'fingerprintjs2';



// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDlcz8dc5wvVWMK8WDRPlq3Y7pYRwgGIAM",
  authDomain: "auth.memehaha.lol",
  projectId: "bitnews-b750f",
  storageBucket: "bitnews-b750f.appspot.com",
  messagingSenderId: "907931117046",
  appId: "1:907931117046:web:047fd767ae18b124ac93d7",
  measurementId: "G-NNE8DE06XP"
};

const getArrayFromLocalStorage = (key) => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : [];
  };


  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app); // Firebase Auth instance
  const db = getFirestore(app); // Firestore instance
  const provider = new GoogleAuthProvider(); // Google Auth provider instance
  
  // Function to handle login
  
  
  const handleLogin = async () => {
    try {
      // Sign in with Google popup
      if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
        window.ReactNativeWebView.postMessage("open-in-chrome");
      } else {
        console.error("Error: ReactNativeWebView or postMessage is not available.");
      }
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
  
      // Generate browser fingerprint
      const browserFingerprint = await generateBrowserFingerprint();
  
      // Check if browser fingerprint exists in Firestore
      const browserRef = doc(db, "browserFingerprints", browserFingerprint);
      const docSnapBrowser = await getDoc(browserRef);
  
      let credits = 10;
  
      // If browser fingerprint exists, set credits to 0
      if (docSnapBrowser.exists()) {
        credits = 0;
      } else {
        // Store the new browser fingerprint
        await setDoc(browserRef, {
          uid: user.uid,
          timestamp: serverTimestamp()
        });
      }
  
      // Check if user document exists in Firestore
      const userRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(userRef);
  
      if (!docSnap.exists()) {
        // User document doesn't exist, create it
        await setDoc(userRef, {
          email: user.email,
          uid: user.uid,
          credits: credits,
          paidBoolean: false,
          browserFingerprint: browserFingerprint // Store browser fingerprint
        });
  
        // Retrieve messages from localStorage
        const messages = getArrayFromLocalStorage('context');
  
        // Reference to messages collection under user document
        const messagesRef = collection(db, 'users', user.uid, 'messages');
  
        // Prepare an array of promises to add all documents
        const addDocPromises = messages.map(async (message) => {
          // Firestore Timestamp for current time
          await addDoc(messagesRef, {
            ...message,
            timestamp: serverTimestamp() // Use Firestore server timestamp
          });
        });
  
        // Execute all addDoc promises concurrently using Promise.all
        await Promise.all(addDocPromises);
  
      } else {
      
      }

    
  
      return true; // Login success
    } catch (error) {
      console.error('Error during login:', error);
      return false; // Login failed
    }
  };
  
  const generateBrowserFingerprint = async () => {
    try {
      const components = await Fingerprint2.getPromise();
      const values = components.map(component => component.value);
      const fingerprint = Fingerprint2.x64hash128(values.join(''), 31);
      return fingerprint;
    } catch (error) {
      console.error('Error generating browser fingerprint:', error);
      return ''; // Return empty string or handle error appropriately
    }
  };
  
  export { handleLogin };