// src/utils/getFonts.js
export const getFonts = () => {
  const testString = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const baseFonts = ['monospace', 'sans-serif', 'serif'];
  const testFonts = [
    // Serif Fonts
    'Times New Roman',
    'Georgia',
    'Garamond',
    'Palatino Linotype',
    'Book Antiqua',
    'Arial',
    'Arial Black',
    'Comic Sans MS',
    'Courier New',
    'Courier',
    'Lucida Console',
    'Lucida Sans Unicode',
    'Tahoma',
    'Trebuchet MS',
    'Verdana',
  
    // Sans-Serif Fonts
    'Arial',
    'Arial Black',
    'Comic Sans MS',
    'Courier New',
    'Georgia',
    'Impact',
    'Lucida Console',
    'Lucida Sans Unicode',
    'Microsoft Sans Serif',
    'Palatino Linotype',
    'Segoe UI',
    'Tahoma',
    'Times New Roman',
    'Trebuchet MS',
    'Verdana',
  
    // Monospace Fonts
    'Courier New',
    'Courier',
    'Lucida Console',
    'Monaco',
    'Consolas',
    'Andale Mono',
    'Inconsolata',
    'Source Code Pro',
    'Fira Code',
  
    // Handwritten Fonts
    'Brush Script MT',
    'Dancing Script',
    'Lucida Handwriting',
    'Segoe Script',
  
    // Display Fonts
    'Impact',
    'Jokerman',
    'Forte',
    'Viner Hand ITC',
    'Papyrus',
    'Algerian',
    'Cooper Black',
  
    // Google Fonts
    'Roboto',
    'Open Sans',
    'Lato',
    'Montserrat',
    'Poppins',
    'Raleway',
    'Oswald',
    'Nunito',
    'PT Sans',
    'Roboto Condensed',
    'Playfair Display',
    'Dancing Script',
    'Merriweather',
    'Quicksand',
    'Source Sans Pro',
  
    // Generic Font Families
    'sans-serif',
    'serif',
    'monospace',
    'cursive',
    'fantasy',
    'system-ui'
  ];
  

  const createTestElement = (fontFamily) => {
    const element = document.createElement('span');
    element.style.position = 'absolute';
    element.style.left = '-9999px';
    element.style.fontSize = '72px';
    element.style.lineHeight = '1em';
    element.style.fontFamily = fontFamily;
    element.innerText = testString;
    document.body.appendChild(element);
    return element;
  };

  const detectedFonts = [];

  const baseMeasurements = baseFonts.reduce((acc, baseFont) => {
    const element = createTestElement(baseFont);
    acc[baseFont] = {
      width: element.offsetWidth,
      height: element.offsetHeight
    };
    document.body.removeChild(element);
    return acc;
  }, {});

  testFonts.forEach(font => {
    baseFonts.forEach(baseFont => {
      const testElement = createTestElement(`${font}, ${baseFont}`);
      const widthWithFont = testElement.offsetWidth;
      const heightWithFont = testElement.offsetHeight;
      if (widthWithFont !== baseMeasurements[baseFont].width || heightWithFont !== baseMeasurements[baseFont].height) {
        if (!detectedFonts.includes(font)) {
          detectedFonts.push(font);
        }
      }
      document.body.removeChild(testElement);
    });
  });

  return detectedFonts;
};
