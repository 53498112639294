import React from 'react';
import './Footer.css';
import logo from './logoblack.png';
import { FaXTwitter } from "react-icons/fa6"; 
import { FaLinkedinIn } from "react-icons/fa";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';// Assuming you create a separate CSS file

const Footer = () => {
    return (
        <div className="footer">
        <div className="footer-left">
            <div className="logo-container-footer">
                <img src={logo} alt="Logo" className="logo" />
            </div>
            <div className="title-container-footer">
                <h1 className="title-footer" >MemeHaHa</h1>
                <p className="subtitle-footer">App Engine Inc</p>
            </div>
        </div>
        <div className="footer-right">
            
              
            <div className="links-container-footer">
            <ul className="links-container-footer">
  <li className="privacyLink-footer">
    <Link to="/privacy-policy">Privacy</Link>
  </li>
  <li className="privacyLink-footer">
    <Link to="/terms-of-service">Terms</Link>
  </li>
  <li className="privacyLink-footer">
    <a href="mailto:admin@memehaha.lol">Contact</a>
  </li>
</ul>

      </div>
            <a href="https://x.com/to8official" target="_blank" rel="noopener noreferrer" className="footer-link-twitter">
                <FaXTwitter src="path-to-twitter-logo.png" alt="Twitter" className="twitter-logo" />
            </a>
            <a href="https://www.linkedin.com/company/memehaha" target="_blank" rel="noopener noreferrer" className="footer-link-twitter">
                <FaLinkedinIn src="path-to-twitter-logo.png" alt="Linkedin" className="twitter-logo" />
            </a>
        </div>
    </div>
    
    
    );
};

export default Footer;