import React, { useState, useEffect } from 'react';
import './Popup.css';
import { MdOutlineDone } from "react-icons/md";
import { useSelector } from 'react-redux';
import { MdClose } from 'react-icons/md'; // Import the close icon
import { FaLock } from "react-icons/fa"; 
import logo from './stripe.webp';
import gems from './diamonds.png';
import { getAnalytics, logEvent } from 'firebase/analytics';

function Popup({ togglePopup, updatePrice }) {
    const [timeLeft, setTimeLeft] = useState({ hours: 0, minutes: 0, seconds: 0 });
    const [selectedCredits, setSelectedCredits] = useState(50);
    const credits = useSelector(state => state);

    useEffect(() => {
        const calculateTimeLeft = () => {
            const now = new Date();
            const utcMidnight = new Date(now);
            utcMidnight.setUTCHours(24, 0, 0, 0);

            const difference = utcMidnight - now;

            const hours = Math.floor(difference / (1000 * 60 * 60));
            const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((difference % (1000 * 60)) / 1000);

            setTimeLeft({ hours, minutes, seconds });
        };

        calculateTimeLeft();
        const timer = setInterval(calculateTimeLeft, 1000);

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        // Call updatePrice with initial values
        logEvent(getAnalytics(), 'popup_shown', {
            name: 'credits_popup',
            time: new Date().toISOString()
        });

        const initialPrice = calculateDiscountedPrice(selectedCredits);
        updatePrice(selectedCredits, initialPrice);
    }, []); // Empty dependency array ensures this effect runs only once on mount

    const addCredits = async () => {
        logEvent(getAnalytics(), 'buy_credits_clicked', {
            credits: selectedCredits,
            price: calculateDiscountedPrice(selectedCredits),
            time: new Date().toISOString()
        });

        const form = document.getElementById('checkout-form');
        form.submit(); 
    };

    const handleSliderChange = (event) => {
        setSelectedCredits(event.target.value);
        const price = calculateDiscountedPrice(event.target.value);
        updatePrice(event.target.value, price);
    };

    const calculatePrice = (credits) => {
        return (credits * 0.008).toFixed(2); // Assuming 50 Gems = $0.4, so 1 Gem = $0.008
    };

    const calculateDiscount = (credits) => {
        const steps = Math.floor(credits / 300);
        const discount = steps * 2.5; // 5% discount per step
        return discount > 100 ? 100 : discount; // Maximum discount is 100%
    };

    const calculateDiscountedPrice = (credits) => {
        const originalPrice = calculatePrice(credits);
        const discount = calculateDiscount(credits);
        const discountedPrice = originalPrice * (1 - discount / 100);
        return discountedPrice.toFixed(2);
    };

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <MdClose className="close-icon" onClick={togglePopup} /> {/* Close icon */}
                
                {credits<10 && ( 

<div className='timer-text-popup'>
 
  <br></br><h3 style={{color:"#aeaeae", marginBottom:"10px"}}>Gems reset in {`${timeLeft.hours.toString().padStart(2, '0')}:${timeLeft.minutes.toString().padStart(2, '0')}:${timeLeft.seconds.toString().padStart(2, '0')}`}
  </h3>
</div>
)}
                <img  src={gems} style={{height:"70px", width:"70px"}} />
              
                <div className='credits-text-popup'>
                    {selectedCredits} Gems 
                </div>
                <div className='price-text-popup'>
                    {selectedCredits == 50 ? (
                        `$${calculatePrice(selectedCredits)} USD`
                    ) : (
                        <>
                            <span className="original-price" style={{ color:"#aeaeae" }}>${calculatePrice(selectedCredits)}</span> ${calculateDiscountedPrice(selectedCredits)} USD
                        </>
                    )}
                </div>
                
                <input 
                    type="range" 
                    min="50" 
                    max="3050" 
                    step="500" 
                    value={selectedCredits} 
                    onChange={handleSliderChange} 
                    className="credits-slider"
                />

                <div className='details-text-popup'>
                    <MdOutlineDone style={{ marginRight: "20px", color: "#007BFF" }} />
                    Use Advanced and Ultimate
                </div>
                <div className='details-text-popup'>
                    <MdOutlineDone style={{ marginRight: "20px", color: "#007BFF" }} />
                    Remove the watermark
                </div>
                <div className='details-text-popup'>
                    <MdOutlineDone style={{ marginRight: "20px", color: "#007BFF" }} />
                    No ads
                </div>
                <div className='details-text-popup'>
                    <MdOutlineDone style={{ marginRight: "20px", color: "#007BFF" }} />
                    Copyright-free memes
                </div>
                <div className='details-text-popup'>
                    <MdOutlineDone style={{ marginRight: "20px", color: "#007BFF" }} />
                    High-quality meme downloads
                </div>
                
                <div>
                    <button className='add-button-popup' onClick={addCredits}>
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <FaLock style={{ marginRight: "10px", fontSize: "18px" }} />
                            Get {selectedCredits} Gems for ${calculateDiscountedPrice(selectedCredits)}
                        </span>
                    </button>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p className='secure-checkout' style={{ color:"#aeaeae" }}>Secured By</p>
                        <img className='stripe-logo' src={logo} alt="Stripe Logo" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Popup;