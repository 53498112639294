import React, { useState, useEffect, useRef } from 'react';
import './PopUpAd.css';
import { MdOutlineDone } from "react-icons/md";
import { useSelector } from 'react-redux';
import { MdClose } from 'react-icons/md'; // Import the close icon
import { FaLock } from "react-icons/fa"; 
import logo from './stripe.png';
import gems from './gems.png';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { IoDiamond } from "react-icons/io5";

function PopUpAd({ togglePopupAd, updatePrice, progress, loading, memeTextPopUp, imagePopUp  }) {
    const [timeLeft, setTimeLeft] = useState({ hours: 0, minutes: 0, seconds: 0 });
    const [selectedCredits, setSelectedCredits] = useState(550);
    const credits = useSelector(state => state);
    const memeContainerRefPopUp = useRef(null); 


    const preventRightClick = (e) => {
        e.preventDefault();
      };

    useEffect(() => {
        const calculateTimeLeft = () => {
            const now = new Date();
            const utcMidnight = new Date(now);
            utcMidnight.setUTCHours(24, 0, 0, 0);

            const difference = utcMidnight - now;

            const hours = Math.floor(difference / (1000 * 60 * 60));
            const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((difference % (1000 * 60)) / 1000);

            setTimeLeft({ hours, minutes, seconds });
        };

        calculateTimeLeft();
        const timer = setInterval(calculateTimeLeft, 1000);

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        // Call updatePrice with initial values
        logEvent(getAnalytics(), 'popup_shown_ad', {
            name: 'credits_popup_ad',
            time: new Date().toISOString()
        });

        const initialPrice = calculateDiscountedPrice(selectedCredits);
        updatePrice(selectedCredits, initialPrice);
    }, []); // Empty dependency array ensures this effect runs only once on mount

    useEffect(() => {
        if (!loading) {
            togglePopupAd();
        }
    }, [loading, togglePopupAd]);

    const addCredits = async () => {
        logEvent(getAnalytics(), 'buy_credits_clicked_ad', {
            credits: selectedCredits,
            price: calculateDiscountedPrice(selectedCredits),
            time: new Date().toISOString()
        });

        const form = document.getElementById('checkout-form');
        form.submit(); 
    };

    const handleSliderChange = (event) => {
        setSelectedCredits(event.target.value);
        const price = calculateDiscountedPrice(event.target.value);
        updatePrice(event.target.value, price);
    };

    const calculatePrice = (credits) => {
        return (credits * 0.008).toFixed(2); // Assuming 50 Gems = $0.4, so 1 Gem = $0.008
    };

    const calculateDiscount = (credits) => {
        const steps = Math.floor(credits / 300);
        const discount = steps * 2.5; // 5% discount per step
        return discount > 100 ? 100 : discount; // Maximum discount is 100%
    };

    const calculateDiscountedPrice = (credits) => {
        const originalPrice = calculatePrice(credits);
        const discount = calculateDiscount(credits);
        const discountedPrice = originalPrice * (1 - discount / 100);
        return discountedPrice.toFixed(2);
    };

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <MdClose className="close-icon" onClick={togglePopupAd} /> {/* Close icon */}
                <h3 style={{color:"gray", marginBottom:"10px", marginTop:"20px"}}>Your meme is being generated... </h3>

         
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:"10px", marginBottom:"10px" }}>
                    <div style={{ width: '60px', height: '60px' }}>
                        <CircularProgressbar 
                            value={progress} 
                            text={`${progress}%`}
                            styles={buildStyles({
                                textSize: '25px',
                                pathColor: '#007BFF',
                                textColor: '#007BFF',
                                trailColor: '#d6d6d6',
                                pathTransitionDuration: 0.5,
                            })}
                        />
                    </div>
                </div>

                <img className='stripe-logo' src={gems} style={{height:"65px", width:"65px"}} />
              
                <div className='credits-text-popup'>
                    {selectedCredits} Gems 
                </div>
                <div>
                <div className='details-text-popup'>
                    <MdOutlineDone style={{ marginRight: "20px", color: "#007BFF" }} />
                    Use Advanced and Ultimate
                </div>
                <div className='details-text-popup'>
                    <MdOutlineDone style={{ marginRight: "20px", color: "#007BFF" }} />
                    Remove the watermark
                </div>
                <div className='details-text-popup'>
                    <MdOutlineDone style={{ marginRight: "20px", color: "#007BFF" }} />
                    No ads
                </div>
                <div className='details-text-popup'>
                    <MdOutlineDone style={{ marginRight: "20px", color: "#007BFF" }} />
                    Copyright-free memes
                </div>
                <div className='details-text-popup'>
                    <MdOutlineDone style={{ marginRight: "20px", color: "#007BFF" }} />
                    High-quality meme downloads
                </div>
                    <button className='add-button-popup' onClick={addCredits}>
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <FaLock style={{ marginRight: "10px", fontSize: "18px" }} />
                            Get {selectedCredits} Gems for ${calculateDiscountedPrice(selectedCredits)}
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default PopUpAd;
