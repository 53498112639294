import React, { useState, useEffect } from 'react';
import './AccountPopup.css';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { MdClose } from 'react-icons/md';
import { handleLogin } from './firebaseLogin';
import { MdAccountCircle } from "react-icons/md";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { FaXTwitter } from "react-icons/fa6"; 
import { FaLinkedinIn } from "react-icons/fa";
import { useDispatch } from 'react-redux'; 
import { getFirestore, doc, getDoc, serverTimestamp } from 'firebase/firestore';

function AccountPopup({ toggleAccountPopup }) {
    const [user, setUser] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
            } else {
                setUser(null);
            }
        });

        logEvent(getAnalytics(), 'account_popup_shown', {
            name: 'account_popup_shown',
            time: new Date().toISOString()
        });

        return () => unsubscribe(); // Cleanup subscription on unmount
    }, []); // Empty dependency array ensures this effect runs only once on mount

    const handleLogout = () => {
        const auth = getAuth();
        signOut(auth).then(() => {
            toggleAccountPopup()
            setUser(null);
            
        }).catch((error) => {
            console.error('Error signing out: ', error);
        });
    };

    const fetchCredits1 = async (uid) => {
        try {
          const db = getFirestore();
          const docRef = doc(db, 'users', uid);
          const docSnap = await getDoc(docRef);
    
          if (docSnap.exists()) {
            dispatch({ type: 'SET_CREDITS', payload: docSnap.data().credits });            
            return docSnap.data().credits;
          } else {
          
            return 0;
          }
        } catch (error) {
          console.error('Error fetching credits:', error);
          return 0;
        }
      };

    const handleLoginPopup=async ()=>{
        await handleLogin()
        const uid = getAuth().currentUser.uid;
        fetchCredits1(uid)
        toggleAccountPopup()
    }

    return (
        <div className="popup-overlay-account">
            <div className="popup-content-account">
            <MdClose className="close-icon-account" onClick={toggleAccountPopup} /> 
            <MdAccountCircle style={{color:"#ffffff", width:"50px", height:"50px"}}></MdAccountCircle>
            {user && (
            <p className='email-account'>{user.email}</p>
            )}
            <li className="privacyLink-account"><Link to="/privacy-policy" onClick={toggleAccountPopup}>Privacy Policy</Link></li>
            <li className="privacyLink-account"><Link to="/terms-of-service" onClick={toggleAccountPopup}>Terms Of Service</Link></li>
            <li className="privacyLink-account"><Link to="/faq" onClick={toggleAccountPopup}>FAQ</Link></li>
             <li className="privacyLink-account">
            <a href="mailto:admin@memehaha.lol" onClick={toggleAccountPopup}>Contact</a>
            </li>
                {/* {user ? (
                    <>
                        <button className='button-login-account' onClick={handleLogout}>Logout</button>
                    </>
                ) : (
                    <>
                        <button className='button-login-account' onClick={handleLoginPopup}>Login</button>
                    </>
                )} */}
               <div style={{ display: 'flex', gap: '15px', marginTop:"10px" }}>
  <a
    href="https://x.com/to8official"
    target="_blank"
    rel="noopener noreferrer"
    className="footer-link-twitter"
  >
    <FaXTwitter alt="Twitter" className="twitter-logo-account" />
  </a>
  <a
    href="https://www.linkedin.com/company/memehaha"
    target="_blank"
    rel="noopener noreferrer"
    className="footer-link-twitter"
  >
    <FaLinkedinIn alt="LinkedIn" className="twitter-logo-account" />
  </a>
</div>

            </div>
        </div>
    );
}

export default AccountPopup;
