// src/Success.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Success.css'

const Success = () => {
    let navigate = useNavigate();
  useEffect(() => {
    // Google Ads Conversion Tracking
    const gtagScript = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=AW-CONVERSION_ID';
    document.head.appendChild(gtagScript);

    const gtagInitScript = document.createElement('script');
    gtagInitScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', '729108147');
      gtag('event', 'conversion', {
        'send_to': 'sLlrCKO0qb4ZELOd1dsC',
        'value': 3.58,
        'currency': 'USD'
      });
    `;
    document.head.appendChild(gtagInitScript);

    return () => {
      document.head.removeChild(gtagScript);
      document.head.removeChild(gtagInitScript);
    };
  }, []);

  const handleClick = () => {
    navigate('/');
  };

  return (
    <div>
      <h1>Payment Successful!</h1>
      <p>Thank you for your purchase.</p>

      <button className="success-page-button" onClick={handleClick}> Generate Memes</button>
    </div>
  );
};

export default Success;
