import React, { useState } from 'react';
import './AccountDeletionForm.css'; // Ensure to import the CSS file
import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged, signInWithRedirect } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc, getDoc, collection, addDoc, serverTimestamp } from "firebase/firestore"; 

const AccountDeletionForm = () => {
  const [email, setEmail] = useState('');
  const [reason, setReason] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const firebaseConfig = {
    apiKey: "AIzaSyDlcz8dc5wvVWMK8WDRPlq3Y7pYRwgGIAM",
    authDomain: "auth.memehaha.lol",
    projectId: "bitnews-b750f",
    storageBucket: "bitnews-b750f.appspot.com",
    messagingSenderId: "907931117046",
    appId: "1:907931117046:web:047fd767ae18b124ac93d7",
    measurementId: "G-NNE8DE06XP"
  };
  
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app); // Firebase Auth instance
  const db = getFirestore(app);

  const handleSubmit = async  (e) => {
    e.preventDefault();
    if (email && reason) {
      // Handle form submission (e.g., send data to API or server)
      console.log('Account deletion request submitted');
      console.log('Email:', email);
      console.log('Reason:', email);
      setSubmitted(true);

      await addDoc(collection(db, "deletionRequest"), {
        email: email,
        reason: email,
      });
    } else {
      alert('Please fill out all fields.');
    }
  };

  return (
    <div className="page-container">

      <div className="main-content">
        <div className="account-deletion-form">
          <h2>Account Deletion</h2>
          {submitted ? (
            <p className="confirmation-message">Your account deletion request has been submitted.</p>
          ) : (
            <form onSubmit={handleSubmit} className="form-container">
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="input-field"
                />
              </div>
              <div className="form-group">
                <label htmlFor="reason">Reason for Deletion:</label>
                <textarea
                  id="reason"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  required
                  className="input-field"
                />
              </div>
              <button type="submit" className="submit-button">Submit</button>
            </form>
          )}
        </div>
      </div>

    </div>
  );
};

export default AccountDeletionForm;
