import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import logo from './logoblack.png';
import './Header.css';
import { Link } from 'react-router-dom';

import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { useSelector, useDispatch } from 'react-redux';
import { IoDiamond } from "react-icons/io5";
import { IoMdAddCircleOutline } from "react-icons/io";
import Popup from './Popup'; 
import AccountPopup from './AccountPopup'; 
import { FaBars, FaTimes } from 'react-icons/fa'; 
import { PiMagicWandBold } from "react-icons/pi";
import { FaRegLaughSquint } from "react-icons/fa";
import { MdAccountCircle } from "react-icons/md";

const Header = ({ isPopupAdOpen }) => {
    const [user, setUser] = useState(false);
    const credits = useSelector(state => state);
    const dispatch = useDispatch();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPopupAccountOpen, setIsPopupAccountOpen] = useState(false);
    const [selectedCredits, setSelectedCredits] = useState(50);
    const [price, setPrice] = useState('0.40');
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const mobileMenuRef = useRef(null); // Ref for mobile menu

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    const toggleAccountPopup = () => {
        setIsPopupAccountOpen(!isPopupAccountOpen);
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const fetchCredits = async (uid) => {
        try {
            const db = getFirestore();
            const docRef = doc(db, 'users', uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                dispatch({ type: 'SET_CREDITS', payload: docSnap.data().credits });
            } else {
                console.log('No such document!');
            }
        } catch (error) {
            console.error('Error fetching credits:', error);
        }
    };

    const updatePrice = (credits, price) => {
        setSelectedCredits(credits);
        setPrice(price);

        const uid = getAuth().currentUser.uid;
        const actionUrl = `https://easyedit.xyz:3000/create-checkout-session?uid=${uid}&credits=${credits}&price=${price}`;

        const form = document.getElementById('checkout-form');
        form.setAttribute('action', actionUrl);
    };

    useEffect(() => {
        onAuthStateChanged(getAuth(), async (authUser) => {
            if (!authUser) {
                setUser(false);
            } else {
                setUser(true);
                const uid = getAuth().currentUser.uid;
                fetchCredits(uid);
            }
        });
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
                setIsMobileMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [mobileMenuRef]);

    return (
        <header className={isPopupAdOpen ? 'header blurred' : 'header'}>
         <div className="logo-container">
    <Link to="/">
        <img src={logo} alt="Logo" className="logo" />
    </Link>
</div>
<div className="title-container">
    <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
        <h1 className="title">MemeHaHa</h1>
        <p className="subtitle">AI-powered Meme Generator</p>
    </Link>
</div>
            {/* <nav className="navigation">
                <ul className="nav-links">
                    <li className="nav-link">
                        <NavLink 
                            to="/" 
                            activeClassName="active" 
                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textDecoration: 'none' }}
                        >
                            <PiMagicWandBold style={{ marginBottom: '4px' }} />
                            <span>Meme Generator</span>
                        </NavLink>
                    </li>
                    <li className="nav-link">
                        <NavLink 
                            to="/latest-memes" 
                            activeClassName="active" 
                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textDecoration: 'none' }}
                        >
                            <FaRegLaughSquint style={{ marginBottom: '4px' }} />
                            <span>Latest Memes</span>
                        </NavLink>
                    </li>
                </ul>
            </nav> */}
            {user && (
                <div>
                    <button className='checkout-button' onClick={togglePopup} id="checkout-button">
                    <IoMdAddCircleOutline className='add-icon' />
                        Balance <IoDiamond className='credits-icon' />
                        {credits}
                        
                    </button>
                    {isPopupOpen && (
                        <Popup togglePopup={togglePopup} updatePrice={updatePrice} />
                    )}
                </div>
            )}
            {user && (
                <form id="checkout-form" method="POST" className="checkout-form">
                </form>
            )}
            <MdAccountCircle className='account-icon' onClick={toggleAccountPopup}></MdAccountCircle>
            {isPopupAccountOpen && (
                        <AccountPopup  toggleAccountPopup={toggleAccountPopup}/>
            )}
            {/* {isMobileMenuOpen ? 
                <FaTimes className="mobile-menu-button" onClick={toggleMobileMenu}/> 
                : 
                <FaBars className="mobile-menu-button"  onClick={toggleMobileMenu}/>
            }
            <div ref={mobileMenuRef} className={`mobile-menu ${isMobileMenuOpen ? 'open' : ''}`}>
                <ul className="mobile-nav-links">
                    <li className="mobile-nav-link">
                        <NavLink 
                            to="/" 
                            activeClassName="active" 
                            onClick={toggleMobileMenu} 
                            style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color:"#ffffff"}}
                        >
                            <PiMagicWandBold style={{ marginRight: '8px' }} />
                            <span>Meme Generator</span>
                        </NavLink>
                    </li>
                    <li className="mobile-nav-link">
                        <NavLink 
                            to="/latest-memes" 
                            activeClassName="active" 
                            onClick={toggleMobileMenu} 
                            style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color:"#ffffff" }}
                        >
                            <FaRegLaughSquint style={{ marginRight: '8px' }} />
                            <span>Latest Memes</span>
                        </NavLink>
                    </li>
                </ul>
            </div> */}
        </header>
    );
};

export default Header;
