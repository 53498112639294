import React from 'react';
import './Faq.css';

const Faq = () => {
  const questionsAndAnswers =  [
    {
      question: "What is memehaha.lol?",
      answer: "memehaha.lol is an AI-powered meme generator website that allows users to generate memes using advanced AI models"
    },
    {
      question: "How does memehaha.lol work?",
      answer: "Users enter a prompt. The AI then uses a combination of text-generation and image-generation to generate the meme."
    },
    {
      question: "Is memehaha.lol free to use?",
      answer: "There is a free model. You get 10 free gems to try out the premium features everyday"
    },
    {
      question: "Can I share memes created on memehaha.lol on social media?",
      answer: "Absolutely! You can share them on social media."
    },
    {
      question: "Are there copyright concerns when using memehaha.lol?",
      answer: "No, there are no copyright concerns. All the memes are generated by the AI and are copyright-free."
    },
    {
      question: "How are payments handled on memehaha.lol?",
      answer: "Payments are securely handled by Stripe, ensuring the safety of your financial information when purchasing gems."
    }
  ];

  return (
    <div className="faq-container">
      <h2 style={{color:"#fff"}}>Frequently Asked Questions</h2>
      <div className="faq-list">
        {questionsAndAnswers.map((item, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question">
              {item.question}
            </div>
            <div className="faq-answer">
              {item.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
