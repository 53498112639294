import React, { useEffect, useState } from 'react';
import { initializeApp } from "firebase/app";
import { getStorage, ref, listAll, getDownloadURL, getMetadata } from 'firebase/storage';
import './MemeFeedTemplate.css';

// Ensure your Firebase setup is imported
const firebaseConfig = {
  apiKey: "AIzaSyDlcz8dc5wvVWMK8WDRPlq3Y7pYRwgGIAM",
  authDomain: "auth.memehaha.lol",
  projectId: "bitnews-b750f",
  storageBucket: "bitnews-b750f.appspot.com",
  messagingSenderId: "907931117046",
  appId: "1:907931117046:web:047fd767ae18b124ac93d7",
  measurementId: "G-NNE8DE06XP"
};

const MemeFeedTemplate = ({ setShowFooter }) => {
  const [memes, setMemes] = useState([]);
  const [loading, setLoading] = useState(false);

  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app);

  useEffect(() => {
    // Hide the footer when this component is mounted
    setShowFooter(false);

    // Optionally show the footer again when the component is unmounted
    return () => setShowFooter(true);
  }, [setShowFooter]);

  const fetchMemes = async () => {
    setLoading(true);
    try {
      const listRef = ref(storage, 'templateMemes/');
      const res = await listAll(listRef);

      const memesWithMetadata = await Promise.all(
        res.items.map(async (itemRef) => {
          const url = await getDownloadURL(itemRef);
          const metadata = await getMetadata(itemRef);
          return { url, timeCreated: metadata.timeCreated };
        })
      );

      // Sort memes by timeCreated in descending order
      const sortedMemes = memesWithMetadata.sort(
        (a, b) => new Date(b.timeCreated) - new Date(a.timeCreated)
      );

      // Get the latest 30 memes
      const latestMemes = sortedMemes.slice(0, 30).map(meme => meme.url);

      setMemes(latestMemes);
    } catch (error) {
      console.error("Error fetching memes: ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMemes(); // Initial fetch
  }, []);

  return (
    <div className="meme-feed-template">
      {loading && <p style={{color:"#ffffff", width:"100%", textAlign:"center"}}>Loading Recent Memes...</p>}
      <div className="meme-column">
        {memes.length > 0 ? (
          memes.filter((_, index) => index % 2 === 0).map((url, index) => (
            <div key={index}>
              <img className="meme-item-template" src={url} alt={`Meme ${index + 1}`} />
            </div>
          ))
        ) : (
          !loading && <p>No memes available.</p>
        )}
      </div>
      <div className="meme-column">
        {memes.length > 0 ? (
          memes.filter((_, index) => index % 2 !== 0).map((url, index) => (
            <div key={index}>
              <img className="meme-item-template" src={url} alt={`Meme ${index + 1}`} />
            </div>
          ))
        ) : (
          !loading && <p>No memes available.</p>
        )}
      </div>
    </div>
  );
};

export default MemeFeedTemplate;
