import { createStore } from 'redux';

// Define reducer function
const creditsReducer = (state = 0, action) => {
  switch (action.type) {
    case 'SET_CREDITS':
      return action.payload;
    default:
      return state;
  }
};

// Create Redux store
const store = createStore(creditsReducer);

export default store;
