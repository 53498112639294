import React from 'react';

const styles = {
  container: {
    maxWidth: '800px',
    margin: 'auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    marginTop: '100px',
    color: '#ffffff',
  },
  heading1: {
    fontSize: '24px',
    marginBottom: '10px',
    color: '#ffffff',
  },
  date: {
    marginBottom: '15px',
  },
  paragraph: {
    marginBottom: '15px',
  },
  heading2: {
    fontSize: '20px',
    marginBottom: '8px',
  },
  list: {
    listStyleType: 'none',
    marginBottom: '15px',
  },
  listItem: {
    marginBottom: '10px',
  },
  contactList: {
    listStyleType: 'none',
    marginBottom: '15px',
  },
};

const TermsOfService = () => {
  return (
    <div style={styles.container}>
      <h1 style={{ ...styles.heading1, color: styles.heading1.color }}>Terms of Service</h1>
      <p style={styles.date}><strong>Last updated:</strong> Jul 16, 2024</p>
      <p style={styles.paragraph}>
        Welcome to memehaha.lol, the AI-powered meme generator. These Terms of Service ("Terms") govern your use of our website and services. By accessing or using memehaha.lol, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our services.
      </p>

      <h2 style={{ ...styles.heading2, color: styles.color }}>1. Use of Our Services</h2>
      <p style={styles.paragraph}>
        You must follow any policies made available to you within our services. You may use our services only as permitted by law. We may suspend or stop providing our services to you if you do not comply with our terms or policies or if we are investigating suspected misconduct.
      </p>

      <h2 style={{ ...styles.heading2, color: styles.color }}>2. Eligibility</h2>
      <p style={styles.paragraph}>
        You must be at least 18 years old to use our services. By using memehaha.lol, you represent and warrant that you are at least 18 years old.
      </p>

      <h2 style={{ ...styles.heading2, color: styles.color }}>3. Your Account</h2>
      <p style={styles.paragraph}>
        To use some of our services, you may need to create an account. You are responsible for safeguarding your account by keeping your password confidential. You agree to notify us immediately of any unauthorized use of your account.
      </p>

      <h2 style={{ ...styles.heading2, color: styles.color }}>4. Data Usage for Analytics</h2>
      <p style={styles.paragraph}>
        By using our website, you agree that we may collect and use your data for analytical purposes. This includes, but is not limited to, information about your usage patterns, preferences, and interactions with our services. The data collected will be used to enhance and improve the user experience, optimize website performance, and develop new features. We are committed to protecting your privacy and will handle your data in accordance with our Privacy Policy. Your data will be anonymized and aggregated to ensure that your personal information remains confidential.
      </p>

      <h2 style={{ ...styles.heading2, color: styles.color }}>5. Prohibited Activities</h2>
      <p style={styles.paragraph}>You agree not to engage in any of the following prohibited activities:</p>
      <ul style={styles.list}>
        <li style={styles.listItem}>Violating any applicable laws or regulations.</li>
        <li style={styles.listItem}>Infringing on the intellectual property or other rights of others.</li>
        <li style={styles.listItem}>Creating or sharing any memes that are unlawful, offensive, or otherwise objectionable.</li>
        <li style={styles.listItem}>Using memes for malicious purposes, including but not limited to harassment, defamation, insulting others, or creating meme coin scams.</li>
        <li style={styles.listItem}>Interfering with or disrupting the integrity or performance of our services.</li>
        <li style={styles.listItem}>Attempting to gain unauthorized access to our systems or user accounts.</li>
      </ul>

      <h2 style={{ ...styles.heading2, color: styles.color }}>6. Payments and Refunds</h2>
      <p style={styles.paragraph}>
        Some of our services may require payment. You agree to pay all applicable fees and taxes. All payments are non-refundable unless otherwise stated. We reserve the right to change our fees at any time by posting the new fees on our website.
      </p>

      <h2 style={{ ...styles.heading2, color: styles.color }}>7. Meme Content</h2>
      <p style={styles.paragraph}>
        Disclaimer on Generated Content
        Our meme generation site uses automated processes by integrating with third party API's to create content. Please be aware that sometimes the generated content may include inappropriate or offensive material. While we strive to minimize such occurrences, we cannot guarantee that all content will be suitable for all audiences. By using our service, you acknowledge and accept this risk. If you encounter any inappropriate content, please report it to us, and we will take appropriate action.
      </p>

      <h2 style={{ ...styles.heading2, color: styles.color }}>8. User Responsibility for Meme Creation</h2>
      <p style={styles.paragraph}>
        Your content. You may provide input to the Services (“Input”), and receive output from the Services based on the Input (“Output”). Input and Output are collectively “Content.” You are responsible for Content, including ensuring that it does not violate any applicable law or these Terms. You represent and warrant that you have all rights, licenses, and permissions needed to provide Input to our Services.
        By using our meme generation services, users acknowledge and agree that they are solely responsible for the content they create. This includes any text, images, or other media incorporated into the memes.
      </p>
      <p style={styles.paragraph}>
        Users must ensure that their created content complies with all applicable laws, regulations, and guidelines. This includes but is not limited to copyright laws, defamation laws, and community standards.
      </p>
      <p style={styles.paragraph}>
        Users are solely responsible for any consequences arising from their created content. This includes legal consequences, public reactions, and any other outcomes resulting from the distribution or use of the memes.
      </p>
      <p style={styles.paragraph}>
        All memes generated by users are considered user-generated content (UGC). By generating a meme on our platform, users agree that the content may include a watermark for branding purposes. This watermark does not imply ownership by our platform but serves to identify the origin of the content.
      </p>
      <p style={styles.paragraph}>
        Users are prohibited from creating content that:
        <ul style={styles.list}>
          <li style={styles.listItem}>Violates any laws or regulations.</li>
          <li style={styles.listItem}>Contains hate speech, threats, or harassment.</li>
          <li style={styles.listItem}>Promotes violence or illegal activities.</li>
          <li style={styles.listItem}>Defames or insults any individual or organization.</li>
          <li style={styles.listItem}>Contains explicit or inappropriate material.</li>
        </ul>
      </p>
      <p style={styles.paragraph}>
        Users agree to indemnify and hold harmless our company, its affiliates, and its employees from any claims, damages, liabilities, and expenses (including legal fees) arising out of or in connection with their use of the meme generation services and the content they create.
      </p>
    
      <h2 style={{ ...styles.heading2, color: styles.color }}>9. Nature of Generated Content</h2>
      <p style={styles.paragraph}>
        The content generated through our AI-powered meme generator is considered user-generated content. Although the content is created using our automated tools, the input provided by users plays a crucial role in the final output. As such, users are solely responsible for the content produced based on their inputs and prompts.
      </p>

      <h2 style={{ ...styles.heading2, color: styles.color }}>10. Intellectual Property</h2>
      <p style={styles.paragraph}>
        memehaha.lol and its licensors own all rights, titles, and interests in and to the services, including all related intellectual property rights. These Terms do not grant you any rights to use the memehaha.lol trademarks, logos, domain names, or other brand features.
      </p>

      <h2 style={{ ...styles.heading2, color: styles.color }}>11. Disclaimers</h2>
      <p style={styles.paragraph}>
        Our services are provided "as is" without warranties of any kind. We do not guarantee that our services will be uninterrupted or error-free. To the fullest extent permitted by law, we disclaim all warranties, whether express or implied, including the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
      </p>

      <h2 style={{ ...styles.heading2, color: styles.color }}>12. Limitation of Liability</h2>
      <p style={styles.paragraph}>
        To the fullest extent permitted by law, memehaha.lol and its affiliates, officers, directors, employees, and agents will not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your use of our services; (b) any unauthorized access to or use of our servers and/or any personal information stored therein; (c) any interruption or cessation of transmission to or from our services; or (d) any bugs, viruses, trojan horses, or the like that may be transmitted to or through our services by any third party.
      </p>

      <h2 style={{ ...styles.heading2, color: styles.color }}>13. Governing Law</h2>
      <p style={styles.paragraph}>
        These Terms will be governed by and construed in accordance with the laws of [Your Country], without regard to its conflict of laws principles.
      </p>

      <h2 style={{ ...styles.heading2, color: styles.color }}>14. Changes to Terms</h2>
      <p style={styles.paragraph}>
        We may modify these Terms at any time by posting the revised Terms on our website. By continuing to use our services after the changes become effective, you agree to be bound by the revised Terms.
      </p>

      <h2 style={{ ...styles.heading2, color: styles.color }}>15. Contact Information</h2>
      <p style={styles.paragraph}>
        If you have any questions or concerns about these Terms, please contact us at:
      </p>
      <ul style={styles.contactList}>
        <li style={styles.listItem}>Email: admin@memehaha.lol</li>
        <li style={styles.listItem}>Email: 17/ 135 D near bible college church ketti, palada,643215 </li>
      </ul>
    </div>
  );
};

export default TermsOfService;
